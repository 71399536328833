import React,{useState} from 'react';
import  './Main.css';

function Main() {

    return(     
    <div id="main-wrapper">
        <div id="introduction">
            <div id="top" class="d-flex flex-column align-items-center justify-content-center">
                <div id="title" class="">
                    <h1 id="title-h1" class="display-4">OZZPROJECTS</h1> 
                </div>
                <div id="links" class="d-flex flex-row justify-content-center">
                    <div id="github" class="mr-2">
                        <h4 class="link-1" ><a href="https://github.com/OzzProjects" target="_blank">Github</a></h4> 
                    </div>
                    <div id="linkedin" class="ml-2">
                        <h4 class="link-1"><a href="https://www.linkedin.com/in/osezele-i-013062172/" target="_blank">LinkedIn</a></h4> 
                    </div>
                </div>
            </div>
        <div id="topdesign">
            <div id="intro_text_space1"></div>
            <div id="intro_text_space2"></div>
            <div id="intro_text_space3"></div>
        </div>
    </div>
    <footer class="d-flex justify-content-center">
    <div id="contact" class="d-flex text-align justify-content-center">
        <p>&copy; OZZPROJECTS | Allrights 2024</p>
    </div>
    </footer>
    </div>
    );
}

export default Main;